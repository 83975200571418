import { createGlobalStyle } from 'styled-components'
import theme from './index'
import convertToCss from './convertToCss'

// put any CSS which should be globally available here (@import's, etc)
const GlobalStylesColors = createGlobalStyle`
    :root {
      --color-shqred: #ff5652;
      --color-shqred2: #ea524f;
      --color-shqred3: #d80505;
      --color-redlt1: #ffebea;
      --color-redlt2: #ffd1cf;
    }
`

export const cssTheme = convertToCss(theme)

export const GlobalStyles = () => (
    <>
        <GlobalStylesColors />

        <style data-id="global-styles">{`:root { ${cssTheme} }`}</style>
    </>
)
